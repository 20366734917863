<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-user-circle-o"></i> User Profile</h2>

        <div class="row">
            <div class="col-12 col-sm-7">
                <form class="form" role="form" style="" @submit.prevent="save">
                    <div class="row">
                        <div class="col-12 col-sm-7">

                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" v-model="item.name" class="form-control required" required/>
                            </div>

                            <div class="form-group">
                                <label>User Name</label>
                                <input type="text" v-model="item.username" class="form-control required" required/>
                            </div>

                            <div class="form-group">
                                <label>Email Address</label>
                                <input type="email" v-model="item.email" class="form-control required" required/>
                            </div>

                            <div class="form-group">
                                <label>Password</label>
                                <input type="password" v-model="item.password" class="form-control" />
                            </div>
                        </div>

                        <div class="col-12 col-sm-5">
                            <div class="form-group">
                                <label>Role</label>
                                <select v-model="item.role_id" class="form-control text-capitalize">
                                    <option value="">-- Role --</option>
                                    <option v-for="role in roles" :key="role._id" :value="role._id">{{role.name}}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Status</label>
                                <select v-model="item.status" class="form-control">
                                    <option selected="" disabled="">-- Select one... --</option>
                                    <option value="active">Active</option>
                                    <option value="disabled">Disabled</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Phone</label>
                                <input type="text" v-model="item.phone" class="form-control"/>
                            </div>

                            <div class="form-group">
                                <label>Time Zone</label>
                                <select v-model="item.timezone" class="form-control text-capitalize">
                                    <option value="" disabled>Time Zone</option>
                                    <option v-for="(timezone, index) in timezones" :value="timezone" :key="index">{{ timezone }}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Notifications</label>
                                <div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="email" v-model="item.notifications" value="email">
                                        <label class="form-check-label" for="email">Email</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group text-right">
                                <button type="submit" class="btn btn-success btn-lg"><span class="glyphicon glyphicon-ok"></span> Save Changes</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import swal from 'sweetalert'
    import moment from "moment-timezone";

    export default {
        name: 'Profile',
        data: function () {
            return {
                item: {
                    notifications: []
                },
                timezones: moment.tz.names(),
                editor: false,
                filter: {name:'', status:''},
                roles:[]
            }
        },
        created: function(){
            this.loadRoles();
            this.load()
        },
        methods:{
            load: function() {
               axios.get(`${this.$root.serverUrl}/admin/profile`, {params:{apikey: this.$root.apikey}}).then(function(response){
                    if(response.data && response.data.data) {
                        this.item = Object.clone({
                            notifications: []
                        }, response.data.data);
                    }
                    delete this.item.password
                }.bind(this)).catch(function (err) {
                    console.error(err.message);
                }.bind(this))
            },
            save: function(){
                //Handle saving the changes
                var request = {'apikey': this.$root.apikey, id:this.item._id, args:this.item};
                this.$root.preloader = true;
                if(!request.args.password) delete request.args.password;

                axios({url:`${this.$root.serverUrl}/admin/users`, data:request, responseType:'json', method:(this.item['_id'])? 'POST' : 'PUT'}).then(function(resp){
                    //Process the results
                    this.$root.preloader = false;
                    if(resp.data && !resp.data.error){
                        //Saved successfully, now update the parent
                        this.$root.user = resp.data.data;
                        swal({title:"Notice", text:"Your changes were saved successfully", icon:"success"});
                    } else {
                        //Handle errors
                        this.errormsg = (resp.data && resp.data.error)? resp.data.error : false;
                        this.$root.preloader = false;
                    }
                }.bind(this)).catch(function(err){
                    this.$root.preloader = false;
                    this.errormsg = err.message||"An unexpected error occured";
                    swal({title:"Error", text: this.errormsg, icon:"error"});
                }.bind(this))
            },
            remove: function(obj){
                //Handle removing the item
                swal({title:"Remove", text:"Are you sure", icon:"info", buttons:['No','Yes']}).then(function(val){
                    if(!val) return;

                    var request = {'apikey': this.$root.apikey, id:obj._id};
                    this.$root.preloader = true;

                    axios({url:`${this.$root.serverUrl}/admin/users/${obj._id}`, data:request, responseType:'json', method:'DELETE'}).then(function(resp){
                        //Process the results
                        this.$root.preloader = false;
                        if(resp.data && !resp.data.error){
                            //Saved successfully, now update the parent
                            swal({title:"Remove", text:"Your request was completed successfully", icon:"success"});
                        } else {
                            //Handle errors
                            swal({title:"Remove", text:"Unable to complete your request", icon:"error"});
                        }
                    }.bind(this))
                        .catch(function(err){
                            this.$root.preloader = false;
                            this.errormsg = err.message||"An unexpected error occured";
                            swal({title:"Error", text: this.errormsg, icon:"error"});
                        }.bind(this))
                }.bind(this));
            },
            loadRoles: function(){
                //Load the items
                var request = {'apikey': this.$root.apikey};
                this.$root.preloader = true;

                return axios.get(`${this.$root.serverUrl}/admin/roles/list`, {params:request}).then(function(resp){
                    //Store the stats
                    this.roles = (resp.data && resp.data.data)? resp.data.data : [];
                    this.$root.preloader = false;
                }.bind(this));
            },
        }
    }
</script>

<style></style>
